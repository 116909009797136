import React from "react";
import { Col, Container, Row } from "react-bootstrap/esm";
import { Link } from "react-router-dom";

import PurposeImg from "../../assets/purpose.jpg"

export default function Section1() {
  return (
    <Container fluid style={{ marginTop: "5%" }}>
      <Row
        className="justify-content-md-center"
        style={{ backgroundColor: "#eee" }}
      >
        <Col md={4} style={{ margin: "5% 0" }}>
          <h3 text-align="center">Internet Society Chapitre Haiti</h3>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <Link to="/">Accueil</Link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Opportunités
              </li>
            </ol>
          </nav>
        </Col>
      </Row>
      <Container>
        <Row className="justify-content-md-center" style={{ marginTop: "2%" }}>
          <Col md={6} style={{ marginTop: "5%" }}>
            <img
              src={PurposeImg}
              alt="logo"
              width="100%"
              className="rounded"
            />
          </Col>
          <Col md={6} style={{ marginTop: "4%" }}>
            <h4>
              {" "}
              <a href="https://www.lacnic.net/4905/2/lacnic/lideres-20">
                Lideres 2.0 (LACNIC)
              </a>
            </h4>
            <p>
              Líderes 2.0 est l’évolution du programme traditionnel du LACNIC
              visant à soutenir les initiatives nationales, régionales,
              sous-régionales et des jeunes du Forum sur la gouvernance de
              l’Internet (NRI) en Amérique latine et dans les Caraïbes. Alors
              que Líderes fournissait initialement un soutien pour
              l'organisation des NRI au niveau local et/ou pour que les
              coordinateurs locaux des NRI participent en face-à-face à la
              réunion préparatoire régionale du Forum sur la gouvernance de
              l'Internet (LACIGF), Líderes 2.0 fournira un type de soutien
              différent qui sera avantageux pour le travail continu que mènent
              les communautés locales de la gouvernance de l’internet.
            </p>
            <b>Bourses de voyages et de formations</b>
            <h4>
              <a href="https://www.internetsociety.org/policy-programs/youth-ambassadors/">
                Youth IGF Ambassador program
              </a>
            </h4>
            <p>
              Chaque année, l'Internet Society choisit quinze jeunes passionnés
              pour participer au programme de jeunes ambassadeurs, permettant à
              la prochaine génération de leaders de l'Internet de collaborer et
              d'innover pour un monde meilleur.
            </p>
            <h4>
              <a href="https://www.icann.org/public-responsibility-support/nextgen">
                ICANN NEXTGEN
              </a>
            </h4>
            <p>
              L'organisation ICANN recherche la prochaine génération de
              personnes intéressées à s'engager activement dans leurs
              communautés régionales et à façonner l'avenir de la politique
              mondiale de l'Internet. Un travail important se déroule chaque
              jour à l'ICANN.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <h4>
              <a href="https://www.icann.org/fellowshipprogram">
                ICANN Fellowship
              </a>
            </h4>
            <p>
              L'objectif du programme de bourses de l'ICANN est de renforcer la
              diversité du modèle multipartite en favorisant les opportunités
              permettant aux individus issus de communautés mal desservies et
              sous-représentées de devenir des participants actifs dans la
              communauté de l'ICANN.
            </p>
            <h4>
              <a href="https://www.intgovforum.org/en/content/supporting-participation-at-the-annual-igf-meeting">
                UN IGF Travel support
              </a>
            </h4>
            <p>
              Un nombre limité de candidats éligibles des pays en développement
              sont soutenus pour participer à la réunion annuelle de l'IGF.
            </p>
            <h4>
              <a href="https://www.internetsociety.org/fellowships/mid-career/">
                Mid Career Fellowship
              </a>
            </h4>
            <p>
              La bourse de mi-carrière est une opportunité unique qui offre à 15
              boursiers un accès inégalé à des établissements d'enseignement et
              à des experts de classe mondiale dans le cadre d'un programme de
              sept mois, de juin à décembre. Il dote les champions Internet
              mondiaux à mi-carrière des connaissances, des compétences et des
              outils nécessaires pour permettre un leadership inclusif,
              innovant, holistique et stratégique.
            </p>
          </Col>
          <Col md={6}>
            <h4>
              <a href="https://www.rightscon.org/young-leaders-summit/">
                The RightsCon Young LeadersSummit
              </a>
            </h4>
            <p>
              Le sommet des jeunes leaders de la RightsCon rassemble des
              étudiants et des jeunes professionnels pour un atelier d'une
              journée la veille du début de la programmation officielle de la
              RightsCon.
            </p>
            <h4>
              <a href="https://www.internetsociety.org/fellowships/early-career/">
                Early Career Fellowship
              </a>
            </h4>
            <p>
              La bourse Early Career Fellowship offre un accès inégalé à des
              experts de classe mondiale, notamment la professeure Laura
              DeNardis de l’Université américaine et d’éminents universitaires
              de l’Oxford Internet Institute. Il offre également un savoir-faire
              en matière de gestion de projet, de plaidoyer et de diplomatie,
              fournissant ainsi aux boursiers les bases nécessaires pour devenir
              de futurs champions d'Internet.
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
