import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

function BasicExample({ image, title, text, link }) {
  return (
    <Card style={{ width: '100%', border: "1px solid #fff" }}>
      <Card.Img variant="top" src={image} />
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Text>
          {text}
        </Card.Text>
        <Button variant="primary" style={{ backgroundColor: "#fff", color: "#000", border: "1px solid #fff" }} href={link} >Voir plus</Button>
      </Card.Body>
    </Card>
  );
}

export default BasicExample;