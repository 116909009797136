import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Col, Container, Row } from "react-bootstrap/esm";
import { Link } from "react-router-dom";

export default function Politique() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container fluid className="marginTop">
      <Row
        className="justify-content-md-center"
        style={{ backgroundColor: "#eee" }}
      >
        <Col md={4} style={{ margin: "5% 0" }}>
          <h3 text-align="center"> Politique de confidentialité</h3>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <Link to="/">Accueil</Link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Politique de confidentialité
              </li>
            </ol>
          </nav>
        </Col>
      </Row>
      <Container>
        <Row className="justify-content-md-center" style={{ marginTop: "2%" }}>
          <Col md={12} style={{ marginTop: "1%" }}>
            <p>
              Nous collectons des données uniquement pour les personnes
              inscrites à nos évènements dans le but de les contacter, de les
              inviter dans nos activités et les inviter à devenir membres. Vos
              données sur la plateforme Internet Society sont protégées par 
              <a href="https://www.internetsociety.org/fr/privacy-policy/"> l’avis de confidentialité de l’Internet Society</a>  qui a été rentrée
              en vigueur : le 9 janvier 2022.
            </p>
          </Col>
        </Row>
      </Container>
      <Helmet>
          <title>ISOC Haïti | Politique de confidentialité </title>
          <meta name="description" content="L'association haïtienne Internet Society Chapitre Haïti (ISOC HT) ou ISOC Haïti est l'un des chapitres de l'Internet Society" />
        </Helmet>
    </Container>
  );
}
