import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Section1 from "../pages-sections/presse";

export default function Presse() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="marginTop">
      <Section1 />
      <Helmet>
        <title>{` ISOC HAITI | la presse`}</title>
        <meta name="description" content="L’Internet Society Haiti Dans la presse"/>
      </Helmet>
    </div>
  );
}
