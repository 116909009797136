import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import LoginForm from "../components/LoginForm";

export default function Login() {
  return (
    <Container>
      <Row>
        <Col md={12} className="d-flex justify-content-center">
          <LoginForm />
        </Col>
      </Row>
    </Container>
  );
}
