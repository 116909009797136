import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Section1 from "../pages-sections/about/section1";

export default function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="marginTop">
      <Section1 />
      <Helmet>
        <title>
          
          ISOC Haïti | A propos{" "}
        </title>
        <meta
          name="description"
          content="L’association haïtienne Internet Society Chapitre Haïti (ISOC HT) ou ISOC Haïti est l’un des chapitres de l’Internet Society, organisme œuvrant dans la gouvernance mondiale de l’Internet. Elle a pour mission de promouvoir sur le territoire haïtien et au bénéfice de tous les conditions et les outils propices au développement d’une Société de l’Information et de la connaissance respectueuse de la culture et des valeurs haïtiennes."
        />
      </Helmet>
    </div>
  );
}
