import React, { useEffect } from "react";
import Section1 from "../pages-sections/membre";

export default function Membre() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="marginTop">
      <Section1 />
    </div>
  );
}
