import React from "react";

export default function Partnaire() {
  return (
    <section id="partnaire">
      <div className="container mt-5">
        <div
          className="row justify-content-md-center py-5"
          style={{ backgroundColor: "#eee" }}
        >
          <div className="col-12 justify-content-md-center">
            <h3
              style={{ borderBottom: "2px solid black", textAlign: "center" }}
            >
              Nos partenaires
            </h3>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 col-12">
            <img
              src="https://orc.org.ht/static/media/img.bbfa3d29f0c33de6f989.jpg"
              className="mt-5"
              width="100%"
              alt="transversal"
            />
          </div>
          <div className="col-md-4 col-12">
            <img
              src="https://mms.businesswire.com/media/20200901006096/fr/817590/23/InternetSociety_Foundation_Logo_Primary_GroundNavy_RGB.jpg"
              width="100%"
              alt="isoc"
            />
          </div>
          <div className="col-md-4 col-12 justify-content-md-center">
            <img
              src="https://vantbefinfo.com/wp-content/uploads/2023/04/conatel-haiti.png"
              className="mt-4 mx-5"
              width="250px"
              height="250px"
              alt="conatel"
            />
          </div>
          <div className="col-md-4 col-12">
            <img
              src="https://www.globalencryption.org/wp-content/uploads/2020/05/GEC-graphics-01.png"
              className="mt-5"
              width="100%"
              alt="gec"
            />
          </div>
          <div className="col-md-4 col-12">
            <img
              src="https://www.coe.int/documents/10463064/14588322/IGF_870-489.jpg/198a629c-b228-832d-9f78-8d4f9f518f87?t=1534093990000"
              className="mt-5"
              width="100%"
              alt="igf"
            />
          </div>
          <div className="col-md-4 col-12">
            <img
              src="https://yt3.googleusercontent.com/ytc/APkrFKZDqT41EF53ru5kNqYI8mnQNudoibx81CXRlSUSUQ=s900-c-k-c0x00ffffff-no-rj"
              className="mt-4 mx-5"
              width="250px"
              height="250px"
              alt="at-large"
            />
          </div>
          <div className="col-md-4 col-12">
            <img
              src="https://community.icann.org/download/attachments/34607287/NPOC_Logo_web_4c.jpg?version=1&modificationDate=1520344989000&api=v2"
              className="mt-5"
              width="100%"
              alt=""
            />
          </div>
          <div className="col-md-4 col-12">
            <img
              src="https://images.squarespace-cdn.com/content/v1/604f4f7bdad32a12b24382e6/ea68a3dd-8b45-4e43-a00e-c79a56213ef7/LOGO+FINALE+WEB+.png"
              className="mt-5"
              width="100%"
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
  );
}
