import React from "react";
import notFound from "../assets/notfound.svg";

function NotFound() {
  return (
      <div className="container mt-5 marginTop">
          <div className="row">
              <div className="col-8 mx-auto mt-5 mb-5">
                  <h3>Ressource introuvable</h3>
                  <img src={notFound} alt="not found" width="100%" />
              </div>
          </div>
      </div>
  );
}

export default NotFound;