import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

// Initialize Firebase
const config = {
  apiKey: "AIzaSyDm7uMHFkf6yoBufz9Hfcb2SjhnnEXNd8E",
  authDomain: "network-e996c.firebaseapp.com",
  projectId: "network-e996c",
  storageBucket: "network-e996c.appspot.com",
  messagingSenderId: "595448769661",
  appId: "1:595448769661:web:c47ee5e2ed5c944cea9514",
  measurementId: "G-FCDCRSDQ35",
  /** 
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  */
};

const app = initializeApp(config);

const db = getFirestore(app);

export const auth = getAuth(app);
export const storage = getStorage(app);

export default db;
