/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
// layoutes
import Nav from "./layouts/nav/index";
import Footer from "./layouts/footer/index";

// pages
import Home from "./pages/home";
import About from "./pages/about";
import Service from "./pages/service";
import Membre from "./pages/membre";
import Presse from "./pages/presse";
import Contact from "./pages/contact";
import Purpose from "./pages/purpose";
import NotFound from "./pages/NotFound";
import Login from "./pages/login";
import Form from "./pages/form";
import Prix from "./pages/prix";
import Honneur from "./pages/honneur";
import Article from "./pages/article";
import Politique from "./pages/politique"

import { auth } from "./firebase/firebaseConfig.js";

function RoutesPage() {
  const [isLogging, setisLogging] = useState(false);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        // const uid = user.uid;
        setisLogging(true);
        // ...
        // console.log("uid", uid)
      } else {
        // User is signed out
        // ...
        console.log("user is logged out");
      }
    });
  }, []);
  return (
    <div className="container-fluid p-0">
      <Router>
        <Nav />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/projet" element={<Service />} />
          <Route path="/membre" element={<Membre />} />
          <Route path="/presse" element={<Presse />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/purpose" element={<Purpose />} />
          <Route path="/prix" element={<Prix />} />
          <Route path="/honneur" element={<Honneur />} />
          <Route path="/politique" element={<Politique />} />

          <Route exact path="*" element={<NotFound />} />
          {isLogging ? (
            <Route path="/login" element={<Form />} />
          ) : (
            <Route path="/login" element={<Login />} />
          )}
          <Route path="/article/:articleId" element={<Article />} />
        </Routes>

        <Footer />
      </Router>
    </div>
  );
}

export default RoutesPage;
