import React, { useState } from "react";
import { Card, Carousel } from "react-bootstrap";
import { animated } from "react-spring";

export default function CardCarousel({ items }) {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <Card  style={{ width: '100%' }}>
      <Carousel activeIndex={index} onSelect={handleSelect}>
        {items.map((item) => (
          <Carousel.Item key={item.id}>
            <animated.img
              className="d-block w-100 "
              src={item.image}
              alt={`Slide ${item.id}`}
              
            />
            <Carousel.Caption className="carousel-text position-absolute top-50 start-50 translate-middle text-center"></Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
    </Card>
  );
}
