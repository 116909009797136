import React, { useState } from "react";
import { useFormik } from "formik";
import contactFormSchema from "../yup/contactFormSchema";
import { setData } from "../services/dataService";

function FormContact() {
  const [success, setSuccess] = useState(false);
  const [load, setLoad] = useState(false);

  const onSubmit = (values, actions) => {
    setLoad(true);
    setData("email", values);
    setSuccess(true);
    actions.resetForm();
    setLoad(false);
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        name: "",
        email: "",
        message: "",
      },
      validationSchema: contactFormSchema,
      onSubmit,
    });

  return (
    <form onSubmit={handleSubmit} className="col-md-10 mx-auto formContact">
      {success ? (
        <div class="alert alert-success" role="alert">
          Votre message a été envoyé
        </div>
      ) : null}
      <div className="row mt-5">
        <div className="col">
          <input
            type="text"
            className={
              errors.name && touched.name
                ? "form-control control-lg is-invalid p-3"
                : "form-control control-lg p-3"
            }
            value={values.name}
            name="name"
            placeholder={"name"}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <div className="invalid-feedback">{"name"}</div>
        </div>
        <div className="col">
          <input
            type="text"
            className={
              errors.email && touched.email
                ? "form-control control-lg is-invalid p-3"
                : "form-control control-lg p-3"
            }
            value={values.email}
            name="email"
            placeholder={"email"}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <div className="invalid-feedback">{"email"}</div>
        </div>
      </div>

      <div className="form-group mt-3 mb-5">
        <textarea
          className={
            errors.message && touched.message
              ? "form-control control-lg is-invalid mt-3"
              : "form-control control-lg mt-3"
          }
          value={values.message}
          name="message"
          id="exampleFormControlTextarea1"
          rows="10"
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <div className="invalid-feedback">{"10 caractères minimum"}</div>
        <button type="submit" className="btn btn-dark mt-3 form-control py-4">
          {load ? (
            <span
              className="spinner-border spinner-border-lg"
              role="status"
              aria-hidden="true"
            >
              {" "}
            </span>
          ) : (
            "Contact"
          )}
        </button>
      </div>
    </form>
  );
}

export default FormContact;
