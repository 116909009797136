import React from "react";
import { Helmet } from "react-helmet";
import SectionPrix from "../pages-sections/honneur/index";
import { Container, Row } from "react-bootstrap";

export default function Prix() {
  return (
    <Container fluid className="marginTop">
      <Row>
        <SectionPrix />
      </Row>
      <Helmet>
          <title>ISOC Haïti | Honneur et merite </title>
          <meta name="description" content="L'association haïtienne Internet Society Chapitre Haïti (ISOC HT) ou ISOC Haïti est l'un des chapitres de l'Internet Society" />
        </Helmet>
    </Container>
  );
}
