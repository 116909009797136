import React from "react";
import { Col, Container, Row } from "react-bootstrap/esm";
import { Link } from "react-router-dom";

import Card from "../../components/Card";

export default function Section1() {
  return (
    <Container fluid style={{ marginTop: "5%" }}>
      <Row
        className="justify-content-md-center"
        style={{ backgroundColor: "#eee" }}
      >
        <Col md={4} style={{ margin: "5% 0" }}>
          <h3 text-align="center"> L’Internet Society Haiti Dans la presse </h3>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <Link to="/">Accueil</Link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Presse
              </li>
            </ol>
          </nav>
        </Col>
      </Row>
      <Container>
        <Row className="justify-content-md-center" style={{ marginTop: "2%" }}>
          <Col md={3} style={{ marginTop: "2%" }}>
            <Card
              image="https://www.haitilibre.com/images-a/g-25507.jpg"
              title=" Premier Forum international sur la Gouvernance de l’Internet Haïti"
              text=" Premier Forum international sur la Gouvernance de l’Internet Haïti 2018"
              link="https://www.icihaiti.com/article-25507-icihaiti-technologie-premier-forum-international-sur-la-gouvernance-de-l-internet-haiti-2018.html"
            />
          </Col>
          <Col md={4} style={{ marginTop: "2%" }}>
            <Card
              image="https://images.unsplash.com/photo-1606814540563-5c02d62fd409?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=Mnw0MjI3MDJ8MHwxfHJhbmRvbXx8fHx8fHx8fDE2NzkwMTY2NjY&ixlib=rb-4.0.3&q=80&w=1080"
              title="Télécommunications"
              text=" Un premier forum en Haïti sur la gouvernance d’Internet "
              link="https://www.alterpresse.org/spip.php?article23539"
            />
          </Col>
          <Col md={4} style={{ marginTop: "2%" }}>
            <Card
              image="https://loopnewslive.blob.core.windows.net/liveimage/sites/default/files/2023-06/649e0f8422c9d.png"
              title="L’ISOC organise un forum sur l’intelligence artificielle en HaïtiL’ISOC organise un forum sur l’intelligence artificielle en Haïti | Loop Haiti"
              text="L’ISOC organise un forum sur l’intelligence artificielle en Haïti | Loop Haiti"
              link="https://haiti.loopnews.com/content/lisoc-organise-un-forum-sur-lintelligence-artificielle-en-haiti#"
            />
          </Col>
          <Col md={12} style={{ marginTop: "2%" }}>
            <iframe
              width="100%"
              height="700"
              src="https://www.youtube.com/embed/rXrmpBtiLYQ?si=Om1qkhfIwIVAu2PD"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
