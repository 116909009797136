import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap/esm";
import { Link } from "react-router-dom";

import { getDocs, collection } from "firebase/firestore";
import db from "../../firebase/firebaseConfig.js";

import Card from "../../components/Card";

export default function Section1() {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    async function getCommunity() {
      const query = collection(db, "price");
      const data = await getDocs(query);
      setArticles(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    }
    getCommunity();
  }, []);

  return (
    <Container fluid>
      <Row
        className="justify-content-md-center"
        style={{ backgroundColor: "#eee" }}
      >
        <Col md={4} style={{ margin: "5% 0" }}>
          <h3 text-align="center">Honneur et merite </h3>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <Link to="/">Accueil</Link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
              honneur et merite
              </li>
            </ol>
          </nav>
        </Col>
      </Row>
      <Container>
        <Row className="" style={{ marginTop: "2%" }}>
          {articles.map((value) => (
            <Col key={value.id} md={4} style={{ marginTop: "2%" }}>
              <Card
                image={value.imgUrl}
                title={value.title}
                text={`${value.desc.toString().substring(0,150)} ...`}
                link={`/article/${value.id}`}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </Container>
  );
}
