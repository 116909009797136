import React, { useEffect } from "react";
import Section1 from "../pages-sections/service/section1";

export default function Service() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="marginTop">
      <Section1 />
    </div>
  );
}
