import RoutesPage from './routes';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {
  return (
    <RoutesPage />
  );
}

export default App;
