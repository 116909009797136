/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import { Col, Container, Row } from "react-bootstrap/esm";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import CardCarousel from "../../components/CardCarousel";
import Card from "../../components/Card";

// img item1
import Img1 from "../../assets/FGI/img1.jpg";
import Img2 from "../../assets/FGI/img2.jpg";
import Img3 from "../../assets/FGI/img3.jpg";
import Img4 from "../../assets/FGI/img4.jpg";
import Img5 from "../../assets/FGI/img5.jpg";

// img item2

import Img6 from "../../assets/EHGI-20240219T174107Z-001/EHGI/img6.jpeg";
import Img7 from "../../assets/EHGI-20240219T174107Z-001/EHGI/img7.jpeg";
import Img8 from "../../assets/EHGI-20240219T174107Z-001/EHGI/img8.jpeg";
import Img9 from "../../assets/EHGI-20240219T174107Z-001/EHGI/img9.jpeg";
import Img10 from "../../assets/EHGI-20240219T174107Z-001/EHGI/img10.jpeg";

// img item3
import Img11 from "../../assets/CN/img11.jpg";
import Img12 from "../../assets/CN/img12.jpg";
import Img13 from "../../assets/CN/img13.jpg";
import Img14 from "../../assets/CN/img14.jpg";
import Img15 from "../../assets/CN/img15.jpg";

export default function Section1() {
  const items1 = [
    {
      id: 1,
      image: Img1,
    },
    {
      id: 2,
      image: Img2,
    },
    {
      id: 3,
      image: Img3,
    },
    {
      id: 4,
      image: Img4,
    },
    {
      id: 5,
      image: Img5,
    },
  ];

  const items2 = [
    {
      id: 1,
      image: Img6,
    },
    {
      id: 2,
      image: Img7,
    },
    {
      id: 3,
      image: Img8,
    },
    {
      id: 4,
      image: Img9,
    },
    {
      id: 5,
      image: Img10,
    },
  ];

  const items3 = [
    {
      id: 1,
      image: Img11,
    },
    {
      id: 2,
      image: Img12,
    },
    {
      id: 3,
      image: Img13,
    },
    {
      id: 4,
      image: Img14,
    },
    {
      id: 5,
      image: Img15,
    },
  ];

  return (
    <Container fluid style={{ marginTop: "5%" }}>
      <Row
        className="justify-content-md-center"
        style={{ backgroundColor: "#eee" }}
      >
        <Col md={5} style={{ margin: "5% 0" }}>
          <h3 text-align="center">
            Les Projets phares de l'internet Society Chapitre Haiti
          </h3>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <Link to="/">Accueil</Link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Projets phares
              </li>
            </ol>
          </nav>
        </Col>
      </Row>
      <Container>
        <Row style={{ marginTop: "4%" }}>
          <Col className="reveal fade-left">
            <h3>Les Forums sur la Gouvernance de l’Internet Haiti</h3>
            <h4>À propos du Forum sur la gouvernance de l'Internet en Haïti</h4>
            <p>
              FGI Haïti est une plate-forme ouverte, inclusive et transparente
              pour un dialogue sur les questions de gouvernance d'Internet
              présentant un intérêt pour la population haïtienne. Le FGI
              national est reconnu par le FGI mondial. Il adhère aux principes
              fondamentaux du FGI et, en tant que tel, ses processus sont
              ascendants, multipartites, ouverts, inclusifs, transparents et non
              commerciaux.
            </p>
            <h5>Processus du FGI en Haïti</h5>
            <p>
              FGI Haïti est un processus annuel dans lequel le comité
              d’organisation multipartite s’adresse à sa communauté et lui
              demande quels sujets lui importent pour être discutés lors de la
              réunion annuelle nationale en Haïti.
            </p>
            <h5>
              Les principaux objectifs du FGI pour Haïti sont les suivants:
            </h5>
            <ul>
              <li>
                Sensibiliser et comprendre les problèmes liés à la gouvernance
                de l'Internet{" "}
              </li>
              <li>
                Avoir des impacts positifs sur le développement des capacités
                des parties prenantes pour leur permettre de participer
                activement aux processus de gouvernance de l’Internet aux
                niveaux national, régional et international.
              </li>
              <li>
                Faciliter les discussions multipartites et la coopération sur
                les problèmes liés à Internet qui préoccupent particulièrement
                le peuple haïtien.
              </li>
              <li>
                Renforcer les liens entre les parties prenantes locales et les
                processus de gouvernance de l'Internet dans la région et au
                niveau international
              </li>
            </ul>
          </Col>

          <Col
            xs={12}
            md={6}
            style={{ marginTop: "6%" }}
            className="reveal fade-right"
          >
            <CardCarousel items={items1} />
          </Col>
          <Col md={12}>
            <h5>Communauté du FGI Haiti</h5>
            <p>
              L'adhésion est basée sur la participation volontaire à une liste
              de diffusion ouverte et sur la participation à toutes les réunions
              en ligne et sur place. La communauté comprend des parties
              prenantes de tous les groupes (gouvernements, organisations
              intergouvernementales, secteur privé, communauté technique,
              société civile, universités).
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h3
              style={{ marginBottom: "3%", borderBottom: "1px solid black" }}
              text-align="center"
            >
              Consultez notre article et nos ressources.
            </h3>
          </Col>
          <Col md={4}>
            <Card
              image="https://i2.wp.com/rezonodwes.com/wp-content/uploads/2020/09/Forum.png?resize=696%2C420&ssl=1"
              title="ISOC Haïti organise une première édition virtuelle"
              text="ISOC Haïti organise une première édition virtuelle de Forum International sur la Gouvernance de l’Internet "
              link="https://rezonodwes.com/?p=192521 "
            />
          </Col>
          <Col md={4}>
            <Card
              image="https://cdn.happeningnext.com/events5/banners/256ac8246f152a05032dc57cc5bff5bb1b9af2b8cc9eddbbf264672171a1d1ea-rimg-w1080-h1080-gmir.jpg?v=1640022230"
              title="la Gouvernance de lInternet Haiti 2021"
              text="Forum sur la Gouvernance de lInternet Haiti 2021 | Le Plaza Hotel, Port-au-Prince, CE | December 21, 2021"
              link="https://happeningnext.com/event/forum-sur-la-gouvernance-de-linternet-haiti-2021-eid4sntt94cjf1?ref=ep_sharer "
            />
          </Col>
          <Col md={4}>
            <Card
              image="https://media.licdn.com/dms/image/D4E22AQHpvOExVMgQ9A/feedshare-shrink_800/0/1687540448424?e=1710979200&v=beta&t=0tNibJe1Zbo6bDBkP0adLXoP5SqqUa8hRn6KPB_vV5Q"
              title=" le grand Forum annuel sur la gouvernance de l’Internet"
              text="Ce 30 juin 2023, le grand Forum annuel sur la gouvernance de l’Internet est de retour en Haïti autour de l’intelligence artificielle et ses enjeux.
                Découvrez nos intervenants nationaux et internationaux tout en vous inscrivant gratuitement pour participer à cet évènement prestigieux organisé par Internet Society Chapitre Haiti en partenariat avec Fondation Internet Society, UN IGF, LACNIC, Loop Haiti."
              link="https://www.linkedin.com/posts/isochaiti_ce-30-juin-2023-le-grand-forum-annuel-sur-activity-7078057660964556800-fzRw/?originalSubdomain=fr"
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h3
              style={{ marginBottom: "3%", borderBottom: "1px solid black" }}
              text-align="center"
            >
              Les Ecoles sur la Gouvernance de l’Internet
            </h3>
          </Col>
          <Col md={12}>
            <p>
              L'Ecole Haitïenne sur la Gouvernance de l'Internet est un projet
              de formation destiné aux divers secteurs et groupes d’intervenants
              d’Haïti visant à les former avec des notions liées à la
              gouvernance de l’Internet pour qu’ils puissent être en mesure de
              participer aux débats sur la gouvernance de l’internet aux niveaux
              national, régional et mondial. Favorisant un espace
              d’apprentissage et de dialogue multipartite, cette initiative
              contribuera à accroître la qualité et l’efficacité de la
              participation dans les débats sur la gouvernance de l’Internet.
            </p>
          </Col>
          <Col md={12} className="reveal fade-left">
            <CardCarousel items={items2} />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h3
              style={{ marginBottom: "3%", borderBottom: "1px solid black" }}
              text-align="center"
            >
              Formations sur les réseaux communautaires
            </h3>
          </Col>
          <Col md={12}>
            <p>
              Les réseaux communautaires se réfèrent aux infrastructures de
              télécommunications déployées et exploitées par un groupe local
              pour répondre à ses propres besoins de communication. Ils sont le
              résultat de personnes travaillant ensemble, combinant leurs
              ressources, unissant leurs efforts et se rapprochant pour combler
              l’absence ou les lacunes de connectivité.
            </p>
            <p>
              Le mode de gestion et les conditions d’accès sont entièrement
              autonomes et peuvent être définis à leur guise selon les
              ressources et partenariats dont ils disposent. Concrètement, les
              réseaux communautaires peuvent, en général, couvrir divers besoins
              comme l’accès au réseau de télécommunication, l’accès à Internet
              ou peuvent être une radio et/ou télévision locale ou autre.
            </p>
            <p>
              En ce qui concerne les réseaux communautaire sans fil avec
              Internet, en dehors de l’accès aux ressources et services de la
              toile qui sont entre autres l’accès au commerce électronique et
              aux services de télé-santé, l’apprentissage à distance,
              l’engagement social et politique, les services gouvernementaux et
              l’information sur la sécurité publique, les réseaux communautaires
              favorisent fondamentalement la connectivité aux populations
              exclues du fait de leur zones géographique, du pouvoir économique
              ou d’absence d’infrastructures de télécommunication.
            </p>

            <p>
              Programme de formation théorique :{" "}
              <a href="/">
                120 professionnels du secteur privé et de l’Etat formés en
                réseaux communautaires
              </a>{" "}
            </p>
            <p>
              Programme de formation pratique{" "}
              <a href="https://rezonodwes.com/?p=276319">
                L’ISOC Haiti veut agir pour l’accès universel à Internet en
                Haïti par une formation pratique{" "}
              </a>{" "}
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h3
              style={{ marginBottom: "3%", borderBottom: "1px solid black" }}
              text-align="center"
            >
              Enquête nationale sur l’accès et l’utilisation de l’Internet en
              Haïti
            </h3>
          </Col>
          <Col md={12}>
            <p>
              <a href="https://rezonodwes.com/?p=246203">
                L’insatisfaction des utilisateurs Internet haïtiens exprimée
                dans un rapport de l’ISOC Haïti
              </a>
            </p>
            <p>
              Internet joue un rôle important dans la vie quotidienne des
              individus et dans tous les aspects de la société humaine. Il
              évolue en permanence et offre aux citoyens des possibilités
              d’accéder à des informations et des services, de se connecter et
              de communiquer, ainsi que de partager des idées et des
              connaissances, et ce à l’échelle planétaire. La qualité de
              l’Internet est importante pour profiter des bénéfices de l’accès à
              Internet en Haïti car l’insécurité affecte nos moindres mouvements
              mais avec l’Internet, nous sommes sûrs de rester connecté au monde
              et les opportunités y relatives.
            </p>
          </Col>
          <Col md={12}>
            <h3
              style={{ marginBottom: "3%", borderBottom: "1px solid black" }}
              text-align="center"
            >
              Installation des réseaux communautaires en milieu rural
            </h3>
          </Col>
          <Col md={6}>
            <p>
              <a href="https://rezonodwes.com/?p=322224">
                Un Internet haut débit commence à toucher les derniers
                kilomètres en Haïti grâce à la Fondation Internet Society
              </a>
            </p>
            <p>
              Le programme de subventions Beyond the Net Large Grant de la
              Fondation Internet Society qui offre un financement pour aider les
              chapitres de l’Internet Society à mettre en œuvre des projets
              innovants qui ont un impact local en fournissant un accès
              significatif à un Internet ouvert, fiable et connecté continue de
              donner des résultats concrets en Haïti en fournissant de la
              connectivité Internet à haut débit à ceux qui en ont le plus
              besoin à des fins de communication.
            </p>
          </Col>
          <Col md={6}>
            <CardCarousel items={items3} />
          </Col>
          <Col md={12}>
            <h3
              style={{ marginBottom: "3%", borderBottom: "1px solid black" }}
              text-align="center"
            >
              Livres et articles
            </h3>
          </Col>
          <Col md={12}>
            <p>
              <a href="https://www.internetsociety.org/fr/blog/2020/10/reseaux-communautaires-ameliorer-la-connectivite-pour-tous-en-haiti/">
                Réseaux communautaires : Améliorer la connectivité pour tous en
                Haïti
              </a>
            </p>
            <p>
              La pandémie du COVID-19 nous rappelle la transition historique
              provoquée par Internet. Il occupe une place réelle dans nos vies
              d’aujourd’hui et de demain. Célébrer, prier, jouer, étudier,
              travailler, s’exprimer… ces verbes ont été conjugués des milliers
              de fois, dans le monde entier, grâce à Internet. En Haïti,
              nombreux sont ceux qui souffrent de l’inégalité flagrante en
              matière d’accès à Internet dans les zones rurales et urbaines. Il
              est clair que pour s’attaquer à ces problèmes, il convient de
              construire un chemin sûr vers la décentralisation de
              l’infrastructure Internet ici.
            </p>
            <p>
              <a href="https://issuu.com/isochaiti/docs/livre_sur_les_reseaux_communautaires">
                Les réseaux communautaires sans fil pour la démocratisation de
                l’accès à internet en Haïti{" "}
              </a>
            </p>
            <p>
              C’est en ce sens que l’ISOC Haïti, qui est un chapitre de
              l’Internet Society, a réalisé une formation sur les réseaux
              communautaires, considérés comme l’un des meilleurs moyens pouvant
              pallier au problème de l’accessibilité de l’internet et favoriser
              le développement du pays. Ainsi, sous son patronage plus d’une
              centaine de jeunes, provenant de partout dans le pays, ont suivi
              avec succès près d’un mois de formation sur les réseaux
              communautaires dans le cadre du programme CN Skills 2020. Parmi
              ces jeunes, quatre-vingt-sept d’entre eux sont certifiés et sont à
              présent prêts à mener des projets en réseaux communautaires
              pouvant changer le cours de la vie des gens dans les communautés
              dans lesquelles ils évoluent.
            </p>
            <p>
              <a href="https://www.amazon.fr/Aper%C3%A7u-gouvernance-lInternet-Ha%C3%AFti-monde/dp/B0BCWFC3T9">
                Aperçu de la gouvernance de l’Internet : Haïti dans le monde{" "}
              </a>
            </p>
            <p>
              Ce livre actualisé sur la gouvernance de l’Internet veut combler
              cette carence de documents pour favoriser l’accès à l’information
              dans un monde numérique pour une population éclairée maîtrisant
              les enjeux et prête à défendre une position commune qui stimulera
              chacun à jouer pleinement leur rôle en nourrissant l’idée que
              l’Internet est pour tout le monde.
            </p>
            <p>
              Un forum national sur la gouvernance de l’Internet, une école
              haïtienne sur la gouvernance de l’Internet et pourquoi ne pas
              savourer aussi un aperçu de la gouvernance de l’Internet pour
              guider et inciter tous les haïtiens intéressés à participer aux
              débats nationaux, régionaux et internationaux sur la gouvernance
              de l’Internet pour s’orienter vers un horizon plein de sujets à
              maîtriser afin de mieux se positionner et défendre les intérêts
              d’Haïti dans le monde. La gouvernance de l’Internet, la guerre
              d’Ukraine et ses menaces pour l’Internet, la cybersécurité, les
              droits de l’homme sur Internet, les technologies émergentes, les
              débuts d’Internet en Haïti, la transformation numérique inclusive,
              l’accès à Internet en Haïti sont en autres autant de titres qui
              sont traités dans ce livre.
            </p>
            <p>
              <a href="https://www.isocfoundation.org/fr/story/haitis-isoc-chapter-pushes-for-community-networks-with-two-grants-from-the-internet-society-foundation/">
                Le chapitre d’Haïti de l’ISOC soutient les réseaux
                communautaires grâce à deux bourses de la Fondation Internet
                Society
              </a>
            </p>
            <p>
              Les télécommunications sont trop chères en Haïti, ce qui exclut de
              nombreuses personnes. Nous avons besoin de règles pour assurer
              plus d’équité pour l’accès à Internet. Nous parlons de l’accès à
              la 5G, alors que certains n’ont aucune connexion, ou une connexion
              trop faible pour télécharger un simple PDF. Mais chacun a droit à
              Internet.
            </p>
          </Col>
        </Row>
      </Container>
      <Helmet>
        <title>{` ISOC HAITI | Projets phares`}</title>
        <meta name="description" content="Les Projets phares de l'internet Society Chapitre Haiti"/>
      </Helmet>
    </Container>
  );
}
