import React from "react";
import { Col, Container, Row } from "react-bootstrap/esm";
import { Link } from "react-router-dom";

export default function Section1() {
  return (
    <Container fluid>
      <Row
        className="justify-content-md-center"
        style={{ backgroundColor: "#eee" }}
      >
        <Col md={4} style={{ margin: "5% 0" }}>
          <h3 text-align="center">
            {" "}
            Prix national et International
          </h3>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <Link to="/">Accueil</Link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Prix national et International
              </li>
            </ol>
          </nav>
        </Col>
      </Row>
      <Container>
        <Row className="justify-content-md-center" style={{ marginTop: "2%" }}>
          <Col md={12} style={{ marginTop: "1%" }}>
            <h3>Prix national et International </h3>
            <p>
              Depuis 2018, avec le badge Gigabit, l'Internet Society Chapitre
              Haïti est l'un des rares chapitres de l'Internet Society dans le
              monde qui est allé au-delà des exigences d'un chapitre de
              l'Internet Society avec le niveau le plus élevé. 
               <b> ChapterThon</b> est une opportunité de l’Internet Society qui
              vise à développer un projet qui fait une différence significative
              dans votre communauté et au-delà.{" "}
            </p>
            <p>
              <a href="https://www.youtube.com/embed/ArTMMFskuGk?si=fOqX1CzTA9_h7y8O">
                Chapterthon 2020 Winner
              </a>{" "}
              Projet de l'Internet Society Chapitre Haïti qui a formé des femmes
              et des mères locales à l'utilisation de l'argent mobile.{" "}
            </p>
            <p>
              <a href="https://www.youtube.com/embed/XiAFnomtwSk?si=HFeOkUlHoEd75TvX">
                ChapterThon 2021 Winner
              </a>{" "}
              Teknokid a formé de futurs leaders et augmenté le pourcentage
              d'enfants en STEM en organisant des camps de codage et
              d'entrepreneuriat pour 20 enfants (tranche d'âge 7-11 ans) dans
              les zones rurales d'Haïti spécifiquement au Nord d'Haïti (Cap
              Haïtien).
            </p>
          </Col>
          <Col md={6}>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/ArTMMFskuGk?si=fOqX1CzTA9_h7y8O"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </Col>
          <Col md={6}>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/XiAFnomtwSk?si=HFeOkUlHoEd75TvX"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
